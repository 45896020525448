import { render, staticRenderFns } from "./ProfileEmailDialog.vue?vue&type=template&id=261eddfe&scoped=true&"
import script from "./ProfileEmailDialog.vue?vue&type=script&lang=js&"
export * from "./ProfileEmailDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261eddfe",
  null
  
)

export default component.exports