<template>
  <v-dialog v-model="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <v-row justify="start" align="center">
          <div><v-icon>email</v-icon></div>
          <div class="headline ml-2">{{$t('profile.email.texts.dialogTitle')}}</div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
        <v-row>
          <v-col cols="12" class="mt-4">
            <v-form ref="form">

              <!-- Current email -->
              <v-text-field
                readonly
                :value="item.email"
                :label="$t('profile.email.form.currentEmail')"
                maxlength="255"
                outlined>
              </v-text-field>

              <!-- New email -->
              <v-text-field
                prepend-inner-icon="email"
                v-model="form.newEmail"
                :label="$t('profile.email.form.newEmail')"
                maxlength="255"
                outlined>
              </v-text-field>

              <!-- Current password -->
              <v-text-field
                v-model="form.current_password"
                :label="$t('profile.email.form.currentPassword')"
                type="password"
                prepend-inner-icon="lock"
                outlined>
              </v-text-field>


            </v-form>

          </v-col>

          <v-col cols="12">
            <AlertBar />
          </v-col>

          <v-col cols="12">
            <ButtonBarFormDialog
              class="mt-5"
              :isLoadingBtnSave="isLoadingBtn.save"
              v-on:cancel="close"
              v-on:submit="customSubmit"/>
          </v-col>

        </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </v-dialog>

</template>

<script>
  // Mixins
  import dialog from '@/mixins/dialog';

  export default {

    name: "ProfileEmailDialog",

    props: {
    },

    components: {
      ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/Bars/AlertBar")
    },

    mixins: [dialog],

    data() {
      return {

        form: {
          current_password: "",
          email: "",
        },

        isLoadingBtn:
        {
          save: false
        }
      }
    },

    methods: {
      customSubmit() {
        this.isLoadingBtn.save = true;
        this.$http.put(`/fm-accounts/${this.$session.get('id')}/email`, this.form, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res => {
          this.close(true);
        })
        .catch(err => {
          if (err && err.response && err.response.status === 400)
            this.$store.commit('alert/showError', this.$t('profile.email.errors')[err.response.data.type]);
          else
            this.$store.commit('alert/showError', this.$t('common.errors.500'));
        })
        .finally(() => {
          this.isLoadingBtn.save = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>